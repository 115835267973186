.icon-thin {
  stroke-width: 30;
  stroke: var(--white);
}

.icon-sm {
  font-size: 16px;
}

.icon-md {
  font-size: 18px;
}

.icon-light-grey {
  color: var(--light-grey);
}

.icon-grey {
  color: var(--grey);
}
