.ComingSoon {
    background-color: #7752fe;
    min-height: 100vh;
    margin: 0;
  }
  
  .ComingSoon * {
    color: #c2d9ff;
    font-size: 1.55rem;
    line-height: 2.3rem;
    letter-spacing: -0.05em;
  }
  
  .ComingSoon h1 {
    font-size: 120px;
    font-weight: 600;
    line-height: 1em;
    letter-spacing: -0.07em;
    margin: 0;
    text-align: left;
  }
  
  @media only screen and (max-width: 768px) {
    .ComingSoon h1 {
      font-size: 72px;
    }
  }
  
  .ComingSoon .flex-row {
    flex-wrap: wrap;
  }
  
  .ComingSoon .page {
    position: relative;
    min-height: calc(100vh - 4.2rem);
    z-index: 2;
    overflow-x: hidden;
  }
  
  .ComingSoon .header {
    margin: 4.2rem 4.2rem 1rem;
  }
  
  @media only screen and (max-width: 768px) {
    .ComingSoon .header {
      justify-content: center;
      gap: 1rem;
    }
  }
  
  .ComingSoon .header .placeholder {
    flex: 1;
  }
  
  @media only screen and (max-width: 768px) {
    .ComingSoon .header .placeholder {
      flex: 0;
    }
  }
  
  .ComingSoon .background {
    position: fixed;
    bottom: 0;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    width: 100%;
  }
  
  .ComingSoon .background .gradient {
    flex: 1;
    background: rgb(137, 109, 253);
    background: linear-gradient(270deg, #896dfd 0%, #7752fe 100%);
  }
  
  .ComingSoon .background .gradient:nth-child(1) {
    margin-left: 5rem;
  }
  
  .ComingSoon .height80 {
    height: 80vh;
  }
  
  .ComingSoon .height60 {
    height: 60vh;
  }
  
  .ComingSoon .height40 {
    height: 40vh;
  }
  
  .ComingSoon .height20 {
    height: 20vh;
  }
  
  .ComingSoon .content {
    flex-grow: 1;
    margin: 4.2rem;
  }
  
  .ComingSoon .footer {
    margin: 0 4.2rem;
    align-items: center;
  }
  
  @media only screen and (max-width: 768px) {
    .ComingSoon .footer {
      text-align: center;
    }
  }
  
  .ComingSoon .footer div:nth-child(1) {
    flex: 1;
  }
  
  .ComingSoon .footer div:nth-child(2) {
    flex: 0 30rem;
    font-size: 25px;
    font-weight: 300;
    line-height: 37px;
    letter-spacing: -0.05em;
  }
  
  .ComingSoon .footer svg {
    fill: transparent;
    font-size: 9.25rem;
  }
  
  @media only screen and (max-width: 768px) {
    .ComingSoon .footer svg {
      font-size: 6rem;
    }
  }
  
  .ComingSoon .label {
    padding: 0.5rem 2.5rem;
    color: #8e8ffa;
    border: solid 1px #8e8ffa;
    border-radius: 1em;
    font-weight: 500;
    letter-spacing: -0.07em;
  }