.Footer{
    padding: 3.0625rem 1.3125rem 1.8125rem;
    margin-top: 4.375rem;
    background-color: var(--light-grey2);
}

.Footer .container{
    max-width: 80rem;
    margin: 0 auto;
}

.Footer .ant-divider{
    margin: 1.625rem 0 .5rem 0;
}  

.Footer button{
    width: 100%;
    font-size: .9375rem;
    font-weight: 500;
    line-height: 1.5625rem;
    letter-spacing: -0.05em;
}

.Footer .top-footer .footer-links{
    display: flex;
    justify-content: space-between;
    margin: 2.125rem 0 2.4375rem 0;
}

.Footer .top-footer .footer-links .text{
    font-weight: 300;
    color: var(--black);
    margin: 0;
}

.Footer .bottom-footer{
    display: flex;
    justify-content: space-between;
    color: var(--grey);
}

.Footer .bottom-footer div{
    display: flex;
    column-gap: .375rem;
}

@media screen and (min-width: 1024px) {
    .Footer{
        padding: 0 4.1875rem;
        margin-top: 6.6875rem;
    }

    .Footer .top-footer{
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    .Footer .top-footer img{
        margin-top: 3.0625rem;
        padding-bottom: 1.9375rem;
    }

    .Footer .top-footer .footer-links{
        display: flex;
        justify-content: flex-start;
        column-gap: .9375rem;
        margin: initial;
    }

    .Footer button{
        width: 12.4375rem;
        height: 2.8125rem;
        font-size: 1.0625rem;
        line-height: 1.5625rem;
        letter-spacing: -0.05em;
        align-self: self-end;
        justify-self: end;
    }

    .Footer .ant-divider{
        margin: 2.8125rem 0 1.3125rem 0;
    }

    .Footer .bottom-footer{
        padding-bottom: 2.5625rem;
    }

    .Footer .bottom-footer div{
        display: flex;
        column-gap: .9375rem;
    }
}
