.plr-page {
  padding-left: 68px;
  padding-right: 68px;
}

.pt-lg {
  padding-top: 32px;
}

.pb-lg {
  padding-bottom: 32px;
}

.plr-md {
  padding-left: 86px;
  padding-right: 86px;
}

.pt-md {
  padding-top: 27px;
}

.pb-md {
  padding-bottom: 27px;
}

.mt-lg {
  margin-top: 38px;
}

@media (max-width: 800px) {
  .plr-page {
    padding-left: 21px;
    padding-right: 21px;
  }

  .pt-lg {
    padding-top: 16px;
  }

  .plr-md {
    padding-left: 21px;
    padding-right: 21px;
  }
}

.align-center {
  text-align: center;
}
