.ant-modal .ant-modal-content {
  padding: 3.125rem 2.3125rem;
}

@media screen and (min-width: 768px) {
  .ant-modal .ant-modal-content {
    padding: 3.125rem 4.1875rem;
  }
}

.ant-modal .ant-modal-content .ant-modal-close svg {
  color: var(--black);
}

.ant-modal .ant-modal-content .ant-modal-body .modal-inputs {
  display: grid;
  gap: 0.625rem;
  margin: 2.5rem 0;
}

.ant-modal .ant-modal-content .ant-modal-body h2 {
  font-weight: 500;
  line-height: 0;
}

.ant-modal .ant-modal-content .ant-modal-body p {
  font-weight: 300;
  font-size: 17px;
  line-height: 1.5938rem;
  color: var(--grey);
}

.ant-modal .ant-modal-content .ant-modal-body .modal-inputs input {
  padding-left: 1.6875rem;
}

.ant-modal .ant-modal-content .ant-modal-body .modal-inputs input,
.ant-modal .ant-modal-content .ant-modal-body .modal-inputs .residence .ant-input-wrapper {
  height: 3.4375rem;
  font-size: 16px;
  font-weight: 300;
  float: left;
}

.ant-modal .ant-modal-content .ant-modal-body .modal-inputs .residence input {
  display: none;
}

/* Add a dividing line between the label and value sections */
.ant-modal .ant-modal-content .ant-modal-body .modal-inputs .residence .ant-input-group-addon {
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}

/* Position the flag on the left with proper spacing */
.ant-modal .ant-modal-content .ant-modal-body .modal-inputs .residence .ant-select-selection-item {
  display: flex;
  align-items: center;
}

/* Add space for the dropdown arrow */
@media screen and (min-width: 768px) {
  .ant-modal .ant-modal-content .ant-modal-body .modal-inputs .ant-input-group-addon {
    padding: 0 1.6875rem;
    font-size: 16px;
    font-weight: 300;
  }

  .ant-modal .ant-modal-content .ant-modal-body .modal-inputs .residence .ant-select-selection-item div {
    padding-right: 180px;
    position: relative;
  }
}


.ant-modal .ant-modal-content .ant-modal-footer button {
  width: 100%;
  height: 3.4375rem;
}
