.History__wrapper h2 {
  margin-top: 72px;
  margin-bottom: 30px;
}

.History__wrapper h5 {
  margin-top: 6px;
  margin-bottom: 6px;
}

.History__wrapper .flex-row {
  margin: 4px 0;
}

.History__wrapper .box {
  margin-top: 1px;
}

.History__wrapper .flex-row div:nth-child(2) {
  width: 185px;
}

.History__wrapper .badge {
  padding: 0px 12px;
  border-radius: 50px;
  background-color: var(--light-grey2);
  color: var(--grey);
}
