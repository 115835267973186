 .Accordian {
    max-width: 80rem;
    margin: 0 auto;
 }

 h1.accordian-header{
    font-size: 1.875rem;
    font-weight: 400;
    line-height: 2.5rem;
    letter-spacing: -0.05em;
    text-align: center;
    color: var(--black);
    margin-bottom: 3.375rem;
    }

.ant-collapse-item {
    border-top: .0625rem solid var(--light-grey);
    padding: 4px;
}

span.ant-collapse-header-text{
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.5625rem;
    letter-spacing: -0.05em;
    text-align: left;
}

.ant-collapse-content-box p{
    font-size: 1.0625rem;
    font-weight: 300;
    line-height: 1.5625rem;
    letter-spacing: -0.05em;
    text-align: left;
    color: var(--grey);
}

@media screen and (min-width: 1024px) {
    h1.accordian-header{
        font-size: 3.125rem;
        line-height: 3.75rem;
    }
    
    span.ant-collapse-header-text{
        font-size: 1.5625rem;
        font-weight: 400;
    } 
}