.about-cards {
  margin-top: 6.25rem;
  max-width: 80rem;
  margin: 0 auto 5rem;
}

.about-cards h1.about-cards-header {
  font-size: 1.875rem;
  font-weight: 400;
  line-height: 2.5rem;
  letter-spacing: -0.07em;
  text-align: center;
  margin-bottom: 2.5rem;
}

.about-cards .ant-col {
  display: grid;
}

.about-cards .card {
  max-width: 21.75rem;
  width: 100%;
  height: 36rem;
  border-radius: 1.25rem;
  background-color: var(--light-grey2);
  justify-self: center;
}

.about-cards .card .content {
  padding: 2.75rem 1.25rem 0 1.25rem;
}

.about-cards .card .content .content-header {
  font-size: 25px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: -0.05em;
}

.about-cards .card .content .text {
  font-size: 17px;
  font-weight: 300;
  line-height: 25px;
  letter-spacing: -0.05em;
}

.about-cards .card .image {
  display: grid;
}

.about-cards .card .image img {
  position: absolute;
  bottom: 0;
  justify-self: end;
  width: 19.25rem;
}

.about-cards .card.second-card .image img {
  margin-bottom: 44px;
}

.about-cards .card.start-cards .image img {
  justify-self: center;
}

.about-cards .card .icon {
  width: 3.625rem;
  height: 3.625rem;
  border-radius: 6.25rem;
  background-color: var(--accent-400);
  display: grid;
}

.about-cards .card .icon img {
  width: 1.625rem;
  height: 1.6875rem;
  place-self: center;
}

.about-cards .card.middle-card .image {
  background-image: url('../../assets//website/continents.svg');
  border-top-right-radius: 20px;
  padding: 3.125rem 0;
}

@media screen and (min-width: 992px) {
  .about-cards {
    margin: 0.25rem auto 10.75rem;
  }

  .about-cards h1.about-cards-header {
    font-size: 3.125rem;
    font-weight: 300;
    line-height: 3.75rem;
    margin-bottom: 4.1875rem;
  }

  .about-cards .card {
    max-width: 100%;
    height: 46rem;
    box-sizing: border-box;
  }

  .about-cards .card .content {
    padding: 45px 3.5625rem 0;
  }

  .about-cards .card .content .content-header {
    font-size: 35px;
    font-weight: 400;
    line-height: 45px;
    width: 324px;
  }

  .about-cards .card.second-card .image img {
    margin-bottom: 72px;
  }

  .about-cards .card.middle-card {
    height: 23.9375rem;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .about-cards .card.end-cards {
    height: 29.875rem;
  }

  .about-cards .card .image img {
    max-width: 23.125rem;
    width: 100%;
  }
}

@media screen and (min-width: 992px) {
  .about-cards {
    margin: 5rem auto 10.75rem;
  }

  .about-cards h1.about-cards-header {
    font-size: 3.125rem;
    font-weight: 300;
    line-height: 3.75rem;
    margin-bottom: 4.1875rem;
  }

  .about-cards .card {
    max-width: 100%;
    height: 46rem;
    box-sizing: border-box;
  }

  .about-cards .card .content {
    padding: 45px 3.5625rem 0;
  }

  .about-cards .card .content .content-header {
    font-size: 35px;
    font-weight: 400;
    line-height: 45px;
    width: 324px;
  }

  .about-cards .card.second-card .image img {
    margin-bottom: 72px;
  }

  .about-cards .card.middle-card {
    height: 23.9375rem;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .about-cards .card.end-cards {
    height: 29.875rem;
  }

  .about-cards .card .image img {
    max-width: 23.125rem;
    width: 100%;
  }

  .about-cards .card.middle-card .image img {
    max-width: 45%;
  }
}

@media screen and (min-width: 1200px) {
  .about-cards .card.middle-card .image img {
    max-width: 37%;
  }
}
