.StatusLogo {
  margin: 10px;
  width: 86px;
  height: 86px;
  border-radius: 86px;
  text-align: center;
  box-shadow: 0 0 3px 3px var(--light-grey2);
}


.StatusLogo__placeholder {
  margin: 10px;
  width: 76px;
  height: 76px;
  line-height: 76px;
  font-size: 30px;
  font-weight: 600;
  border-radius: 76px;
  text-align: center;
  box-shadow: 0 0 3px 3px var(--light-grey2);
}
