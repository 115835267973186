.Contact{
    background-color: var(--light-blue);
    border-radius: 1.25rem;
    padding: 3rem 1.3125rem;
    max-width: 21.75rem;
    margin: auto;
    position: relative;
    overflow: hidden;
}

.Contact::before {
    position: absolute;
    content: "";
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    background: repeating-linear-gradient(
        -55deg,
        #97BEFE 20%,
        #97BEFE 30%,
        transparent 30%,         
        transparent 30.5%          
      );
    opacity: .2;
    pointer-events: none;
}

.Contact .icon{
    width: 3.625rem;
    height: 3.625rem;
    border-radius: 6.25rem;
    background-color: var(--accent-400);
    display: grid;
  }

  .Contact .icon img {
    width: 1.6875rem;
    height: 1.1875rem;
    place-self: center;
  }

.Contact form input, .Contact form textarea{
    width: 100%;
    border: none;
    border-radius: .3125rem; 
    padding-left: 1.1875rem;
    box-sizing: border-box;
}

.Contact p.text {
    color: var(--grey);
    padding: 1.25rem 0 2.5rem 0;
}

.Contact form input {
    height: 3.125rem;
}

.Contact form .input-errorMsg{
  color: rgba(248, 30, 30, 0.84);
}

.Contact form textarea{
    padding-top: 1.0625rem;
    padding-bottom: 1.0625rem;
    height: 9.4375rem;
}

.Contact h1.header{
    font-size: 1.875rem;
    font-weight: 400;
    line-height: 2.5rem;
    letter-spacing: -0.05em;
    text-align: left;
    color: var(--black);
}

.Contact form button {
    width: 100%;
    height: 3.125rem;
    margin-top: 3.4375rem;
    cursor: pointer;
    border: none;
    font-size: 17px;
    font-weight: 500;
    line-height: 25px;
    letter-spacing: -0.05em;
    border-radius: 5px;
    color: var(--white);
    background-color: var(--accent);
  }

  .Contact form button:hover, .Contact form button:focus {
    transition: background-color 0.3s ease-in-out;
    background-color: var(--accent-200);
  }

  @media screen and (min-width: 1024px) {
    .Contact{
        max-width: 71.625rem;
        width: auto;
        padding: 3.0625rem 3.5625rem;
        max-height: 23.9375rem;
    }

    .Contact .icon{
        width: 4.375rem;
        height: 4.375rem;
      }

      .Contact .icon img {
        width: 2.0625rem;
        height: 1.4375rem;
      }

    .Contact form input {
        height: 2.8125rem;
    }
    
    .Contact form textarea{
        padding-top: 1.0625rem;
        padding-bottom: 1.0625rem;
        height: 8.375rem;
    }

    .Contact form button {
        height: 2.8125rem;
        margin-top: 2.5rem;
      }
  }