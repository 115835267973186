.ServiceDetail .like-link {
  display: flex;
  height: auto;
  padding: 0;
  border: none;
  gap: 0;
  box-shadow: none;
  align-items: end;
}

.ServiceDetail .like-link:hover,
.ServiceDetail .like-link:active {
  background: transparent !important;
}

.ServiceDetail .like-link:focus-visible {
  outline: none;
}

.ServiceDetail .text-like-link {
  font-size: 17px;
  font-weight: 400;
  color: var(--black);
  margin-left: 0.5em;
}

.ServiceDetail .card {
  border: 1px solid var(--accent-900);
  border-radius: 30px;
  background-color: var(--white);
  margin-top: 27px;
}

.ServiceDetail .card .timeframe {
  padding: 12px 15px 12px 15px;
  border-radius: 5px;
  background-color: var(--light-grey2);
  width: fit-content;
  font-size: 15px;
  line-height: 17px;
  color: var(--grey);
}

.StatusModal__chart_wrapper {
  border: 1px solid var(--light-grey);
  border-radius: 30px;
  padding: 20px 36px;
  margin: 20px 0;
  background-color: rgba(245, 245, 245, 0.3);
}

@media (max-width: 640px) {
  .StatusModal__chart_wrapper {
    padding: 20px 12px;
  }
}

.StatusModal__chart_wrapper .chart-label-wrapper {
  font-size: 15px;
  color: var(--grey);
  margin-bottom: 20px;
}

.StatusModal__chart_wrapper .chart-label-wrapper > div:nth-child(1) {
  flex-grow: 1;
}

.StatusModal__chart_wrapper .chart-label-wrapper > div:nth-child(2) {
  justify-content: end;
  align-items: baseline;
  gap: 6px;
}

@media (max-width: 640px) {
  .StatusModal__chart_wrapper .chart-label-wrapper > div:nth-child(2) {
    gap: 0;
  }
}

.ServiceDetail .StatusModal__chart_wrapper .md-box {
  margin-left: 10px;
}

.ServiceDetail .sm-box {
  height: 7px;
  width: 7px;
  border-radius: 1px;
  margin-bottom: 2px;
  margin-right: 5px;
  display: inline-block;
}

.ServiceDetail .footer {
  font-size: 15px;
  line-height: 23px;
  margin-bottom: 20px;
}

@media (max-width: 640px) {
  .ServiceDetail .footer {
    align-items: flex-start;
  }

  .ServiceDetail .footer > div:nth-child(2) {
    margin-left: 36px;
  }
}
.ServiceDetail .footer > div:nth-child(1) {
  flex-grow: 1;
}

.ServiceDetail .footer .info-icon-wrapper {
  border-radius: 150px;
  background-color: var(--light-grey2);
  padding: 4px 7px 3px;
}
