.StatusCard__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-top: 5rem;
}

.StatusCard__wrapper .card-banner {
  display: grid;
  margin-bottom: 12rem;
}

@media screen and (min-width: 768px) {
  .StatusCard__wrapper .card-banner {
    display: flex;
    justify-content: space-between;
    width: calc(100vw - 65px);
    margin-bottom: 4rem;
  }
}

@media screen and (min-width: 829px) {
  .StatusCard__wrapper .card-banner {
    width: calc(100vw - 150px);
  }
}

@media screen and (min-width: 1024px) and (max-width: 1189px) {
  .StatusCard__wrapper .card-banner {
    width: calc(100vw - 200px);
  }
}

@media screen and (min-width: 1281px) {
  .StatusCard__wrapper .card-banner {
    width: calc(100vw - 200px);
  }
}
.StatusCard__wrapper .card-banner .compact {
  margin: 0;
  box-shadow: none;
}

/* Hidden for Beta Launch */
/* .StatusCard__wrapper .group-title {
  margin-bottom: 4.5rem;
} */

.StatusCard__wrapper .status-card {
  width: 320px;
  border-radius: 30px;
  padding: 16px 10px 26px;
  margin-top: 55px;
  background-color: var(--white);
}

.StatusCard__wrapper .status-card .footer-content,
.StatusCard__wrapper .status-card .footer-title {
  margin-left: 10px;
  margin-right: 10px;
}

.StatusCard__wrapper .status-card .footer-content div:nth-child(1) {
  align-self: flex-end;
}

.StatusCard__wrapper .status-card .footer-content div:nth-child(2) {
  flex-grow: 1;
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.25rem;
  color: var(--black);
}

.StatusCard__wrapper .status-card .footer-title {
  font-size: 17px;
  line-height: 20px;
  margin-bottom: 4px;
  color: var(--light-grey);
}
