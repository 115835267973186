:root {
  --accent: #7752fe;
  --accent-900: #190482;
  --accent-800: #270fa4;
  --accent-200: #9c7aff;
  --accent-300: #8e8ffa;
  --accent-400: #edeeff;
  --white: #fff;
  --grey: #6d6b76;
  --light-grey: #cac7d5;
  --light-grey2: #f5f5f5;
  --black: #1e1e1e;
  --bg-accent: #c2d9ff;
  --shadow: 0 2px 4px -1px #0003, 0 4px 5px #00000024, 0 1px 10px #0000001f;
  --green: #50c878;
  --red: #ff0000;
  --light-blue: #c2d9ff;
}

html * {
  font-family: 'Outfit', sans-serif;
  letter-spacing: -0.05em;
}

body {
  margin: 0;
  background-color: var(--white);
}

h1 {
  font-size: 3.75rem;
  font-weight: 600;
  line-height: 4.375rem;
  letter-spacing: -0.2rem;
  text-align: center;
  color: var(--black);
  margin-bottom: 1rem;
}

h2 {
  font-size: 30px;
  font-weight: 600;
  line-height: 1.3em;
  letter-spacing: -0.095rem;
  color: var(--black);
}

h3 {
  font-size: 21px;
  font-weight: 500;
  line-height: 21px;
  color: var(--black);
}

h4 {
  font-size: 1.06rem;
  font-weight: 300;
  line-height: 1.563rem;
  text-align: center;
  color: var(--grey);
  margin-bottom: 3rem;
}

h5 {
  font-size: 17px;
  line-height: 1.5em;
  margin: 0;
}

.text {
  font-size: 17px;
  line-height: 1.5em;
}

.small-text {
  font-size: 15px;
  line-height: 1.5em;
}

.xs-text {
  font-size: 13px;
  line-height: 1.5em;
}

.bold {
  font-weight: 700;
}

.semi-bold {
  font-weight: 600;
}

.medium {
  font-weight: 500;
}

a {
  text-decoration: none;
}

a .anticon {
  vertical-align: bottom;
}

.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.625rem;
}

@media (max-width: 640px) {
  .flex-row.wrap-on-mobile {
    flex-direction: column;
  }
}

.flex-col {
  display: flex;
  flex-direction: column;
}

@media (max-width: 640px) {
  .flex-break-mobile {
    height: 0;
    flex-basis: 100%;
  }
}

.compact {
  max-width: 37.5rem;
  margin: auto;
}

.w50 {
  width: 50%;
}

.w100 {
  width: 100%;
}

@media (min-width: 640px) {
  .desktop-hidden {
    display: none;
  }
}

@media (max-width: 640px) {
  .mobile-hidden {
    display: none;
  }
}

.separator-horizontal {
  margin: 1rem 0;
  border-bottom: 2px solid var(--light-grey2);
}

.separator-horizontal.-lighter {
  border-bottom: 1px solid var(--light-grey2);
}

.separator-30 {
  border-right: solid 1px var(--light-grey);
  height: 30px;
}

.half-px-border {
  box-shadow: 0px 0px 3px 0px var(--light-grey);
}

.black-text {
  color: var(--black);
}

.grey-text {
  color: var(--grey);
}

.red-text {
  color: var(--red);
}

.green-text {
  color: var(--green);
}

.accent-text {
  color: var(--accent);
}

.accent-900-text {
  color: var(--accent-900);
}

.hoverable:hover {
  cursor: pointer;
}

.md-box {
  height: 10px;
  width: 10px;
  border-radius: 2px;
}

.box.-green {
  background-color: var(--green);
}

.box.-yellow {
  background-color: #fabc3c;
}

.box.-red {
  background-color: var(--red);
}
