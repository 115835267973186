.StatusGroup__wrapper .content {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(330px, 1fr));
  grid-auto-rows: auto;
  gap: 2rem;
  justify-content: start;
  margin-bottom: 3rem;
}

.StatusGroup__wrapper .content > * {
  width: 330px;
  margin: 0 auto;
}

.StatusGroup__wrapper .card-banner {
  grid-column: 1 / -1;
  /* width: 100%; */
  margin-bottom: 1rem;
}

@media (max-width: 767px) {
  .StatusGroup__wrapper .content {
    grid-template-columns: 1fr;
    justify-items: center;
  }
}
