/* Hidden for Beta Launch */
/* .SearchForm__wrapper.flex-row {
  border-radius: 10px;
  background-color: var(--white);
  padding: 8px;
  margin-bottom: 16px;
} */

@media (max-width: 640px) {
  .SearchForm__wrapper.flex-row {
    display: block;
    height: 42px;
    margin-bottom: 96px;
  }
}

/* Hidden for Beta Launch */
/* .SearchForm__wrapper .input-wrapper {
  flex-grow: 1;
  gap: 0;
} */

.SearchForm__wrapper .input-wrapper {
  flex-grow: 1;
  gap: 8;
}

.SearchForm__wrapper .input-wrapper .category {
  border: 1px solid var(--accent);
  border-radius: 0.625rem;
  align-content: center;
  height: 3.125rem;
  width: 40%;
}

.SearchForm__wrapper .input-wrapper .search {
  border-radius: 10px;
  border: 1px solid var(--light-grey);
  background-color: var(--white);
  box-sizing: border-box;
  width: 60%;
  height: 3.125rem;
  align-content: center;
}

@media screen and (min-width: 768px) {
  .SearchForm__wrapper .input-wrapper .category {
    width: 10.1875rem;
  }

  .SearchForm__wrapper .input-wrapper .search {
    width: 15.75rem;
  }
}

@media (max-width: 640px) {
  .SearchForm__wrapper .submit-button {
    width: 100%;
    height: 58px;
    padding: 14px;
    border-radius: 5px;
    margin-top: 43px;
  }
}
