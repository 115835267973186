.joinBeta {
  margin: 4.125rem 0 10.9375rem;
}

.joinBeta .btnGroup {
  display: grid;
  gap: 0.625rem;
  margin-top: 1rem;
  justify-content: center;
}

@media screen and (min-width: 768px) {
  .joinBeta .btnGroup {
    display: flex;
  }  
}

.joinBeta .btnGroup button {
  display: flex;
  gap: 0.625rem;
  align-items: center;
  justify-content: center;
  height: 3.4375rem;
  width: 16rem;
  font-size: 1.25rem;
}

.joinBeta .btnGroup .slot {
  border: 0.0313rem solid #1e1e1e;
  border-radius: 0.3125rem;
  display: block;
  align-content: center;
  justify-items: center;
  text-align: center;
  width: 100%;
  height: 3.4375rem;
  font-size: 1.25rem;
  font-weight: 300;
}

@media screen and (min-width: 768px) {
  .joinBeta .btnGroup .slot {
    width: 8.5rem;
  }
}

.joinBeta .btnGroup .slot .slotCount {
  color: var(--accent);
  font-weight: 700;
}
