nav.nav{
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
}

.nav .container{
  max-width: 80rem;
  margin: 0 auto;
}

.nav-mobile-hidden {
    display: none;
}

.nav-desktop-hidden {
    display: initial;
}
  
.nav .link {
    position: relative;
    bottom: auto;
    right: auto;
}

.nav .logo {
    flex-grow: 1;
}

.ant-dropdown-menu-title-content button{
  width: 100%;
}
  
  @media screen and (min-width: 1024px) {
    nav.nav {
        position: relative;
        background-color: #FCFBFB;
        padding-top: 1.75rem;
        padding-bottom: 1.75rem;
      }

      .nav .logo {
        flex-grow: 0;
      }

      .nav .nav-links{
        display: flex;
        margin: 0 auto;
        column-gap: 2.5rem;
      }

      .nav .nav-links .text{
        color: var(--grey);
      }
      
      .nav .link:hover {
        box-shadow: var(--shadow);
    }
    
    .nav .link:active {
        color: var(--black);
    }

    .nav .link {
        position: absolute;
        bottom: -4rem;
        right: 0;
        padding: 1.0625em .625rem;
        border-radius: .625rem;
        text-decoration: none;
        color: var(--black);
        background-color: var(--white);
    }  

    .nav-mobile-hidden {
        display: initial;
    }

    .nav-desktop-hidden {
        display: none;
    }
}