.StatusCardHeader .favorite-wrapper {
  margin: 10px;
}

.StatusCardHeader .favorite-wrapper .favorite-icon {
  color: var(--grey);
  font-size: 24px;
}

.StatusCardHeader .favorite-wrapper .favorite-icon.-saved {
  color: var(--accent);
}

.StatusCardHeader div:nth-child(3) {
  align-self: flex-start;
}

.StatusCardHeader .title {
  flex-grow: 1;
}

.StatusCardHeader .title h3 {
  margin: 0 0 7px 0;
  word-break: break-word;
}

.StatusCardHeader .label {
  font-size: 12px;
  line-height: 12px;
  color: var(--light-grey);
}

.StatusCardHeader .date {
  font-size: 13px;
  font-weight: 500;
  color: var(--accent);
}

.StatusCardHeader .separator-horizontal {
  margin-left: 10px;
  margin-right: 10px;
}

.StatusCardHeader .clickable:hover {
  cursor: pointer;
}

.ServiceDetail .StatusCardHeader .label-wrapper {
  display: flex;
  flex-direction: row;
  gap: 5px;
}

@media (max-width: 640px) {
  .ServiceDetail .StatusCardHeader .label-wrapper {
    flex-direction: column;
  }
}

.ServiceDetail .StatusCardHeader .label-wrapper .label,
.ServiceDetail .StatusCardHeader .label-wrapper .date {
  font-size: 15px;
  line-height: 15px;
}

.ServiceDetail .StatusCardHeader .favorite-wrapper {
  margin: auto;
}

.ServiceDetail .StatusCardHeader .favorite-wrapper .favorite-icon {
  font-size: 32px;
}
