.counter {
  display: flex;
  gap: 0.625rem;
  justify-content: center;
}

@media screen and (min-width: 768px) {
  .counter {
    gap: 1.25rem;
  }
}

.counter .section {
  display: block;
  justify-items: center;
  color: var(--light-grey);
}

.counter .section .group-card {
  display: flex;
  gap: 0.125rem;
}

.counter .section p {
  font-size: 0.8125rem;
  font-size: 500;
  margin-top: 0.5rem;
}

.counter .section.days {
  color: var(--black);
}

.counter .section.days p {
  color: var(--light-grey);
}

.counter .group-card .count-card {
  background-color: var(--light-grey2);
  align-content: center;
  text-align: center;
  width: 2rem;
  height: 2.5625rem;
  border-radius: 0.3125rem;
  font-size: 1.875rem;
  font-weight: 500;
}

@media screen and (min-width: 1024px) {
  .counter .group-card .count-card {
    width: 2.625rem;
    height: 3.1875rem;
  }
} 

@media screen and (min-width: 1280px) {
  .counter .group-card .count-card {
    background-color: var(--white);
  }
} 
